<template>
  <label class="flex cursor-pointer gap-2">
    <span class="my-auto flex border border-gray-3 hw-5">
      <i-fasr-check v-if="modelValue" class="m-auto" />
    </span>
    <input
      v-model="modelValue" type="checkbox"
      hidden
      :true-value="true"
      :false-value="false">
    {{ label }}</label>
</template>

<script setup lang="ts">
type Props = {
  label: string;
};
defineProps<Props>();

const modelValue = defineModel({ default: false, required: true });
</script>
